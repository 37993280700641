<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      md="8"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Edit Mobil Unit</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Mobil Unit</label>
            <b-form-input
              v-model="currentBloodvan.name"
              placeholder="Nama Mobil Unit"
              :state="validation.name"
            />
            <b-form-invalid-feedback :state="validation.name">
              {{ fieldErrors.name }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li class="mb-3">
          <div class="d-block mb-3">
            <label>Vendor</label>
            <b-form-input
              v-model="currentBloodvan.location"
              placeholder="Masukan Lokasi Mobil Unit"
              class="mb-3"
              :state="validation.location"
            />
            <b-form-invalid-feedback :state="validation.location">
              {{ fieldErrors.location }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <!--        <li class="mb-3">-->
        <!--          <div class="d-block mb-3">-->
        <!--            <label>Alamat Mobil Unit</label>-->
        <!--            <b-form-input-->
        <!--              v-model="currentBloodvan.address"-->
        <!--              placeholder="Masukan Alamat Mobil Unit"-->
        <!--              class="mb-3"-->
        <!--              :state="validation.address"-->
        <!--              @input="searchLocation"-->
        <!--            />-->
        <!--            <b-form-invalid-feedback :state="validation.address">-->
        <!--              {{ fieldErrors.address }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </div>-->
        <!--          <div class="d-block mb-3">-->
        <!--            <label>Lokasi Mobil Unit</label>-->
        <!--            <b-form-input-->
        <!--              v-model="currentBloodvan.location"-->
        <!--              placeholder="Masukan Lokasi Mobil Unit"-->
        <!--              class="mb-3"-->
        <!--              :state="validation.location"-->
        <!--            />-->
        <!--            <b-form-invalid-feedback :state="validation.location">-->
        <!--              {{ fieldErrors.location }}-->
        <!--            </b-form-invalid-feedback>-->
        <!--          </div>-->
        <!--          <l-map-->
        <!--            v-if="loadingMap"-->
        <!--            :zoom="zoom"-->
        <!--            :center="center"-->
        <!--            :options="{ zoomControl: false }"-->
        <!--            style="width: 100%; height: 200px; position: relative"-->
        <!--            @click="moveMarker"-->
        <!--          >-->
        <!--            <l-tile-layer :url="url" />-->
        <!--            <l-marker :lat-lng="markerLatLng" />-->
        <!--          </l-map>-->
        <!--        </li>-->
        <li class="mb-3">
          <label>Lokasi Mobil Unit</label>
          <google-maps
            v-model="currentBloodvan.address"
            @address-extracted="handlePlaceSelected"
          />
        </li>
        <li class="d-flex justify-content-between align-items-center">
          <label>Status</label>
          <div
            class="d-flex align-items-center flex-wrap"
            style="gap: 0.5rem"
          >
            {{ isActive === 1 ? "Aktif" : "Tidak Aktif" }}
            <b-form-checkbox
              v-model="isActive"
              switch
              :value="1"
              name="Status"
              size="lg"
              button-variant="danger"
              :true-value="1"
              :false-value="0"
            />
          </div>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li class="mb-3">
          <label>Layanan</label>
          <b-form-input
            v-model="currentBloodvan.service"
            :state="validation.service"
            placeholder="Masukan Layanan Mobil Unit"
            class="mb-3"
          />
          <b-form-invalid-feedback :state="validation.service">
            {{ fieldErrors.service }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Kontak Mobil Unit</label>
          <b-form-input
            v-model="currentBloodvan.contact"
            placeholder="Masukan Kontak Mobil Unit"
            class="mb-3"
            :state="validation.contact"
          />
          <b-form-invalid-feedback :state="validation.contact">
            {{ fieldErrors.contact }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <div class="mb-3">
            <label>Jadwal Operasional</label>
            <b-form-select
              v-model="selectedOption"
              :options="optionsDayorDateChoice"
            />
          </div>
          <b-form-group v-if="selectedOption === 'day'">
            <div
              v-for="option in options"
              :key="option.value"
              class="d-flex align-items-center position-relative"
            >
              <b-form-checkbox
                v-model="option.checked"
                :value="option.value"
                name="days"
                class="mr-2 mb-3"
              >
                {{ option.name }}
              </b-form-checkbox>
              <div
                class="d-flex position-absolute mb-3"
                style="left: 150px"
              >
                <b-button
                  size="sm"
                  variant="dark"
                  class="mr-2 btn-ghost"
                  @click="openModal(option, 'start')"
                >
                  {{ option.timeStart || "08:00" }}
                </b-button>
                <span>-</span>
                <b-button
                  size="sm"
                  variant="dark"
                  class="ml-2 btn-ghost"
                  @click="openModal(option, 'end')"
                >
                  {{ option.timeEnd || "17:00" }}
                </b-button>
              </div>
            </div>
          </b-form-group>
          <div v-else-if="selectedOption === 'date'">
            <div
              v-for="(input, index) in inputs"
              :key="index"
              class="d-flex mb-3 align-items-center"
            >
              <b-form-input
                v-model="input.date"
                type="date"
                class="mr-3"
              />
              <b-button
                size="sm"
                variant="outline-dark"
                @click="openModal(input, 'start')"
              >
                {{ input.timeStart || "08:00" }}
              </b-button>
              <strong class="mx-1"> - </strong>
              <b-button
                size="sm"
                variant="outline-dark"
                @click="openModal(input, 'end')"
              >
                {{ input.timeEnd || "17:00" }}
              </b-button>
              <i
                class="ri-close-circle-fill ml-3 text-danger"
                style="font-size: 1.5em; cursor: pointer"
                @click="removeInputDate(index)"
              />
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                class="text-primary"
                variant="link"
                @click="addInputDate"
              >
                Tambah
              </b-button>
            </div>
          </div>
        </li>
      </ul>
    </b-col>
    <b-col class="d-flex justify-content-end mb-3">
      <b-button
        variant="danger"
        class="mr-3"
        @click="$router.go(-1)"
      >
        Batal
      </b-button>
      <b-button
        variant="primary"
        @click="updateBloodvanData"
      >
        Simpan
      </b-button>
    </b-col>
    <b-modal
      id="modal"
      centered
      cancel-variant="text"
      header-class="align-items-center"
      hide-footer
    >
      <template #modal-title>
        <h5 class="mb-0">
          Jam {{ modalType === "start" ? "Masuk" : "Pulang" }}
        </h5>
      </template>

      <b-form-input
        v-model="inputTime"
        type="time"
        @change="modalType === 'start' ? getOpenTime() : getCloseTime()"
      />

      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button
          variant="text"
          @click="$bvModal.hide('modal')"
        >
          Close
        </b-button>

        <b-button
          class="ml-16"
          variant="primary"
          @click="changeTime"
        >
          Save changes
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import GoogleMaps from '@/components/maps/GoogleMaps.vue'
import BloodvanService from '../../../../api/managebloodvan/manageBloodvanAPI'

export default {
  name: 'EditBloodvan',
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    GoogleMaps,
    LMap,
    LMarker,
    LTileLayer,
  },
  data() {
    return {
      inputTime: '',
      scheduleTime: {
        day: '',
        openHour: '08',
        openMinute: '00',
        closeHour: '17',
        closeMinute: '00',
      },
      isActive: 0,
      modalOption: null,
      modalType: null,
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      center: [51.505, -0.159],
      markerLatLng: { lat: 0, lng: 0 },
      loadingMap: true,
      inputs: [{ date: '', timeStart: '08:00', timeEnd: '17:00' }],
      options: [
        {
          name: 'Senin',
          checked: false,
          value: 'monday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
        {
          name: 'Selasa',
          checked: false,
          value: 'tuesday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
        {
          name: 'Rabu',
          checked: false,
          value: 'wednesday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
        {
          name: 'Kamis',
          checked: false,
          value: 'thursday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
        {
          name: 'Jumat',
          checked: false,
          value: 'friday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
        {
          name: 'Sabtu',
          checked: false,
          value: 'saturday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
        {
          name: 'Minggu',
          checked: false,
          value: 'sunday',
          timeStart: '08:00',
          timeEnd: '17:00',
        },
      ],
      currentBloodvan: null,
      selectedOption: '',
      optionsDayorDateChoice: [
        { value: '', text: 'Pilih Jenis Jadwal' },
        { value: 'date', text: 'Tanggal' },
        { value: 'day', text: 'Hari' },
      ],
      fieldErrors: {
        name: undefined,
        service: undefined,
        address: undefined,
        location: undefined,
        contact: undefined,
        email: undefined,
      },
    }
  },
  computed: {
    validation() {
      return {
        name: this.fieldErrors.name ? false : null,
        service: this.fieldErrors.service ? false : null,
        address: this.fieldErrors.address ? false : null,
        location: this.fieldErrors.location ? false : null,
        contact: this.fieldErrors.contact ? false : null,
        email: this.fieldErrors.email ? false : null,
      }
    },
  },
  watch: {
    currentBloodvan: {
      handler(newVal) {
        if (newVal) {
          Object.keys(this.fieldErrors).forEach(key => {
            if (newVal[key]) {
              this.fieldErrors[key] = null
            }
          })
        }
      },
      deep: true,
    },
    isActive(newValue) {
      if (this.currentBloodvan) {
        this.currentBloodvan.is_active = newValue ? 1 : 0
      }
    },
  },
  created() {
    this.getBloodvanData(this.$route.params.id)
  },
  methods: {
    addInputDate() {
      this.inputs.push({
        date: '',
        timeStart: '08:00',
        timeEnd: '17:00',
      })
    },
    removeInputDate(index) {
      this.inputs.splice(index, 1)
    },
    getBloodvanData(id) {
      BloodvanService.getBloodvanDetail(id)
        .then(response => {
          this.currentBloodvan = response.data.data
          this.isActive = response.data.data.is_active
        })
        .catch(e => console.error(e))
    },
    async updateBloodvanData() {
      try {
        if (!this.currentBloodvan) {
          throw new Error('Current blood van data is null')
        }

        const mapData = {
          id: this.currentBloodvan.id,
          name: this.currentBloodvan.name,
          service: this.currentBloodvan.service,
          address: this.currentBloodvan.address,
          location: this.currentBloodvan.location,
          is_active: this.isActive,
          contact: this.currentBloodvan.contact,
          description: this.currentBloodvan.description,
          pic: 'pic dummy',
          latitude: this.currentBloodvan.latitude,
          longitude: this.currentBloodvan.longitude,
        }

        const scheduleData = this.getScheduleData()

        if (!scheduleData.length) {
          console.warn('No schedule selected')
          this.$bvToast.toast('Pilih setidaknya satu jadwal', {
            title: 'Peringatan',
            variant: 'warning',
            solid: true,
          })
          return
        }

        const mapScheduleData = {
          blood_van_id: this.currentBloodvan.id,
          schedule: scheduleData,
          is_active: this.isActive,
        }

        await Promise.all([
          BloodvanService.updateBloodvan(mapData),
          this.currentBloodvan.schedule === null
            ? BloodvanService.addSchedule(mapScheduleData)
            : BloodvanService.editSchedule(mapScheduleData),
        ])

        this.$router.go(-1)

        this.$bvToast.toast('Mobil Unit Berhasil DiEdit', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        console.error('Error updating bloodvan data:', error)
        if (error.response && error.response.data.code === 400) {
          this.setFieldErrors(error.response.data.message)
        } else {
          this.$bvToast.toast('Terjadi kesalahan saat menyimpan data', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }
      }
    },

    getScheduleData() {
      if (this.selectedOption === 'day') {
        return this.options
          .filter(e => e.checked)
          .map(schedule => {
            const timeData = this.getTimeData(schedule)
            // Berikan nilai default jika waktu kosong
            if (!timeData.open_hour) timeData.open_hour = '08'
            if (!timeData.open_minute) timeData.open_minute = '00'
            if (!timeData.close_hour) timeData.close_hour = '17'
            if (!timeData.close_minute) timeData.close_minute = '00'
            return {
              day: schedule.value,
              ...timeData,
            }
          })
      } if (this.selectedOption === 'date') {
        return this.inputs.map(input => {
          const timeData = this.getTimeData(input)
          // Berikan nilai default jika waktu kosong
          if (!timeData.open_hour) timeData.open_hour = '08'
          if (!timeData.open_minute) timeData.open_minute = '00'
          if (!timeData.close_hour) timeData.close_hour = '17'
          if (!timeData.close_minute) timeData.close_minute = '00'
          return {
            date: input.date,
            ...timeData,
          }
        })
      }
      return []
    },

    getTimeData(data) {
      return {
        open_hour: data.timeStart.split(':')[0],
        open_minute: data.timeStart.split(':')[1],
        close_hour: data.timeEnd.split(':')[0],
        close_minute: data.timeEnd.split(':')[1],
      }
    },

    setFieldErrors(errorMessage) {
      errorMessage.split('\\n').forEach(msg => {
        Object.keys(this.fieldErrors).forEach(key => {
          if (msg.includes(key)) {
            this.fieldErrors[key] = msg
          }
        })
      })
    },
    openModal(option, type) {
      this.modalOption = option
      this.modalType = type
      this.inputTime = type === 'start' ? option.timeStart : option.timeEnd
      this.$bvModal.show('modal')
    },
    changeTime() {
      if (this.modalType === 'start') {
        this.modalOption.timeStart = this.inputTime
      } else {
        this.modalOption.timeEnd = this.inputTime
      }
      this.$bvModal.hide('modal')
    },
    getOpenTime() {
      const [hour, minute] = this.inputTime.split(':')
      this.scheduleTime.openHour = hour
      this.scheduleTime.openMinute = minute
    },
    getCloseTime() {
      const [hour, minute] = this.inputTime.split(':')
      this.scheduleTime.closeHour = hour
      this.scheduleTime.closeMinute = minute
    },
    async searchLocation() {
      const { provider } = this.geosearchOptions
      const results = await provider.search({
        query: this.currentBloodvan.address,
      })
      if (results && results[0]) {
        this.markerLatLng = { lat: results[0].y, lng: results[0].x }
        this.center = this.markerLatLng
      }
    },
    moveMarker(e) {
      this.markerLatLng = e.latlng
      this.currentBloodvan.address = `${this.markerLatLng.lat}, ${this.markerLatLng.lng}`
    },
    // FOR GMAPS API PURPOSES
    handlePlaceSelected(place) {
      this.currentBloodvan.address = place.formattedAddress
      this.currentBloodvan.latitude = place.latitude
      this.currentBloodvan.longitude = place.longitude
    },
  },
}
</script>
